import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import Button from '../common/Button';
import { getStripeCustomerPortal, stripeCheckout } from '../../api/stripe';
import ChangePasswordForm from './ChangePasswordForm';
import UserInfoForm from './UserInfoForm';
import { openModal, useModalContext } from '../../contexts/ModalContext';
import { usePermissionsContext } from '../../contexts/PermissionsContext';
import { PAGE_CREATE_RESTAURANT } from '../../constants/UriConstants';

const AccountInformation = () => {
  const stripe = useStripe();
  const { dispatch } = useModalContext();
  const {
    permissions: { hasPairings, hasImageUpload }
  } = usePermissionsContext();
  const isSuper = hasPairings && hasImageUpload;
  const navigate = useNavigate();

  const getCustomerPortal = async () => {
    try {
      const url = await getStripeCustomerPortal();
      window.location.replace(url);
    } catch (err) {
      console.error(err);

      // This is the no code customer portal that Stripe provides.  For more information: https://docs.stripe.com/customer-management/activate-no-code-customer-portal
      // We have some customers that were set up manually within Stripe's dashboard instead of our onboarding process built with Stripe Checkout.  Since the stripe customer ID is not in our database in this edge case,
      // it'll fail getting the Stripe customer dashboard for the customer.  The alternative is to take them to Stripe's customer portal for account management when it comes to payments.
      window.location.replace(
        "https://billing.stripe.com/p/login/28odR43Be5y54Uw6oo"
      );
    }
  };

  const getStripeCheckout = async () => {
    if (isSuper) {
      navigate(`/${PAGE_CREATE_RESTAURANT}`);
      return;
    }

    const id = await stripeCheckout();
    const result = await stripe.redirectToCheckout({ sessionId: id });
    if (result.error) {
      openModal({
        dispatch,
        message:
          'An unexpected error has occurred. Please email our team at info@taptabapp.com so that we can address this issue promptly.'
      });
    }
  };

  return (
    <div className="account-info-container">
      <UserInfoForm />
      <hr />
      <h2 className="account-info-input-header">CHANGE PASSWORD</h2>
      <ChangePasswordForm />
      <hr />
      <div className="account-info-stripe-container">
        <div className="account-info-current-services">
          <h2 className="account-info-input-header">CURRENT SERVICES</h2>
          <Button className="manage-services-button" onClick={getCustomerPortal}>
            MANAGE SERVICES
          </Button>
        </div>
        <div className="account-info-new-service">
          <h2 className="account-info-input-header">ADD SERVICES</h2>
          <Button className="add-service-button" onClick={getStripeCheckout}>
            ADD RESTAURANT
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccountInformation;
