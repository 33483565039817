import { ColDef } from 'ag-grid-community';
import ItemTable from '../ItemTable';
import DescriptionCell from '../MenuItemTable/cells/DescriptionCell';
import SingleItemCell from '../MenuItemTable/cells/SingleItemCell';
import DiscoveryContentCell from '../MenuItemTable/cells/DiscoveryContentCell';
import LoadingSpinner from '../common/LoadingSpinner';
import { useDiscoveryContentItemsContext } from '../../contexts/DiscoveryContentItemsContext';
import { tag } from '../../types/DiscoveryContentItemInterface';

const DiscoveryContentTable = () => {
  const { discoveryContentItems, isLoading } = useDiscoveryContentItemsContext();

  const categoryLabels = {
    dish_media: 'Dishes',
    dish_story: 'Drinks',
    promotions: 'Other',
    spaces: 'Spaces',
    chef_story: 'Chef Stories',
    vibes: 'Other',
    misc: 'Other'
  };

  const nameCellGetter = (params: any) => ({
    image: params?.data?.media?.length > 0 ? params.data.media[0].mediaUrl : undefined,
    name: params.data.title,
    discoveryContentID: params.data.discoveryContentID,
    isHidden: params.data.hidden
  });

  const itemCellGetter = (params: any, field: string) => {
    const urls = params.data?.urls || [];
    const urlData = urls.find((url: any) => url.type === field);
    return {
      item: urlData ? urlData.url : undefined,
      isHidden: params.data?.hidden || false
    };
  };

  const isHiddenGetter = (params: any, field: string) => ({
    [field]: params.data?.[field],
    isHidden: params.data.hidden || false
  });

  const COLUMNS: ColDef[] = [
    {
      headerName: 'NAME',
      valueGetter: nameCellGetter,
      headerClass: 'name-header',
      cellRenderer: DiscoveryContentCell,
      maxWidth: 300,
      minWidth: 250,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true
    },
    {
      headerName: 'DESCRIPTION',
      valueGetter: (params) => isHiddenGetter(params, 'description'),
      headerClass: 'custom-header',
      cellRenderer: DescriptionCell,
      maxWidth: 300,
      minWidth: 250,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true
    },
    {
      headerName: 'ORDERING LINKS',
      valueGetter: (params) => {
        const item = itemCellGetter(params, 'ordering');
        return {
          ...item
        };
      },
      headerClass: 'custom-header',
      maxWidth: 200,
      cellRenderer: SingleItemCell,
      suppressMovable: true
    },
    {
      headerName: 'RESERVATION LINKS',
      valueGetter: (params) => {
        const item = itemCellGetter(params, 'reservation');
        return {
          ...item
        };
      },
      headerClass: 'custom-header',
      cellRenderer: SingleItemCell,
      suppressMovable: true
    },
    {
      headerName: 'CATEGORIES',
      valueGetter: (params) => {
        const categories = params.data?.categories || [];
        // Map the category objects to their human-readable format
        let categoriesString = '--';

        if (categories.length > 0) {
          if (typeof categories[0] === 'object') {
            categoriesString = categories
              .map(
                (category: { categoryName: keyof typeof categoryLabels }) =>
                  categoryLabels[category.categoryName] || category.categoryName
              )
              .join(', ');
          } else {
            categoriesString = categories
              .map((category: keyof typeof categoryLabels) => categoryLabels[category])
              .join(', ');
          }
        }

        return {
          item: categoriesString, // Pass the concatenated category names
          isHidden: params?.data?.hidden || false
        };
      },

      headerClass: 'custom-header',
      cellRenderer: SingleItemCell,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true
    },
    {
      headerName: 'TAGS',
      valueGetter: (params) => {
        const tags = params.data?.metaTags || [];
        let tagsString = '--';

        // Check if metaTags are objects and extract the tag field
        if (tags.length > 0) {
          if (typeof tags[0] === 'object') {
            tagsString = tags.map((_tag: tag) => _tag.tag).join(', ');
          } else {
            tagsString = tags.map((t: string) => t).join(', ');
          }
        }

        return {
          item: tagsString, // Pass the concatenated tag names
          isHidden: params.data.hidden || false
        };
      },
      headerClass: 'custom-header',
      cellRenderer: SingleItemCell,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true
    }
  ];

  if (isLoading) {
    return (
      <div className="discovery-content-page-loading">
        <LoadingSpinner />
      </div>
    );
  }

  return <ItemTable items={discoveryContentItems} columns={COLUMNS} draggable={false} />;
};

export default DiscoveryContentTable;
