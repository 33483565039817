import React, { useState } from 'react';
import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CSS } from '@dnd-kit/utilities';
import { EditIcon, PlayIcon, TrashCanIcon } from '../../assets/svgs/icons';
import { MenuItemMediaInterface, ThumbnailInterface } from '../../types/MediaInterface';

interface MediaSelectionPreviewProps {
  id: number | string;
  media: MenuItemMediaInterface;
  thumbnail?: ThumbnailInterface;
  multiple: boolean;
  onDelete: Function;
  onEdit: Function;
  onDeleteVideo?: Function;
  // adding this hide edit as a quick implementation to hide edit functionality for videos that do not need thumbnail
  // look to PR: https://github.com/taptabapp/TapManager/pull/622
  hideEdit?: boolean;
}

const MediaSelectionPreview = ({
  id,
  media,
  thumbnail,
  multiple,
  onDelete,
  onDeleteVideo,
  onEdit,
  hideEdit
}: MediaSelectionPreviewProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  const { setNodeRef, listeners, attributes, transform, transition, isDragging } = useSortable({
    id: id?.toString(),
    animateLayoutChanges
  });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleDeleteClicked = (idOfMedia: number | string) => {
    onDelete?.(idOfMedia);
  };

  const handleEditClicked = (idOfMedia: number | string) => {
    onEdit?.(idOfMedia);
  };

  const isVideo = media.type === 'video';

  return (
    <div
      className="multiple-media-preview-container"
      style={itemStyle}
      ref={setNodeRef}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...attributes}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...listeners}
    >
      <div className={`multiple-media-preview ${isDragging ? 'is-dragging' : ''} ${multiple ? 'multiple' : ''}`}>
        <div
          className="image-container"
          style={{
            visibility: loaded ? 'visible' : 'hidden',
            display: isVideo === false || thumbnail || 'thumbnail' in media ? 'block' : 'none'
          }}
        >
          <img
            className="media-preview"
            style={{
              visibility: loaded ? 'visible' : 'hidden',
              display: isVideo === false || thumbnail || 'thumbnail' in media ? 'unset' : 'none'
            }}
            src={thumbnail ? thumbnail.thumbnailURL : media.mediaURL}
            alt="preview of media upload"
            onLoad={async () => {
              setLoaded(true);
            }}
          />
          {isVideo && (thumbnail || 'thumbnail' in media) && <PlayIcon />}
        </div>
        <div
          className="image-container"
          style={{ display: isVideo === true && !thumbnail && !('thumbnail' in media) ? 'unset' : 'none' }}
        >
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video id={`videoPreview${id}`} className="media-preview" src={media.mediaURL}>
            Your browser does not support the video tag.
          </video>
          <PlayIcon />
        </div>
      </div>
      {(!multiple || isVideo === false) && (
        <TrashCanIcon width="12" height="12" onIconClicked={() => handleDeleteClicked(id)} />
      )}
      {isVideo && (
        <TrashCanIcon
          width="12"
          height="12"
          onIconClicked={() => onDeleteVideo(media.mediaID)}
          aria-label="Delete video"
        />
      )}
      {multiple && (isVideo === true || thumbnail) && !hideEdit && (
        <EditIcon width="12" height="12" onIconClicked={() => handleEditClicked(id)} tabIndex={0} />
      )}
    </div>
  );
};

export default MediaSelectionPreview;
